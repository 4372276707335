import { useEffect, useState } from 'react'
import userSession from '../../../../topics/Profile/modules/user-session'
import hasItems from '../../../../modules/has-items'
import hasRequiredFields from '../has-required-customfields'
import consoleLogger from '../../../../modules/console-logger'

const useUserData = (extendedUserData, experienceData) => {
  const [consoleLog] = consoleLogger('UseUserData')

  const [data, setData] = useState()

  useEffect(() => {
    const pianoData = window && window.tp && window.tp.pianoId && window.tp.pianoId.getUser()
    consoleLog('piano data', pianoData)

    if (!hasItems(pianoData)) {
      setData(false)
    }
    if (hasItems(pianoData)) {
      const appData = userSession.getAppData()
      consoleLog('app data', appData)
      const localData = userSession.getData()
      consoleLog('locale data', localData)

      const userData = {
        ...localData,
        ...appData,
        ...pianoData,
      }

      const hasVerification = userData && !!userData.verified

      consoleLog('combined data', userData)
      userData.updateTime = +new Date()
      userData.smsVerified = !!userData.smsVerified
      userData.emailVerified = hasVerification
      consoleLog('email verified', hasVerification)
      if (hasItems(extendedUserData) && extendedUserData) {
        userData.pianoExtended = extendedUserData

        userData.pianoExtended.custom_field_values?.forEach((customField) => {
          userData[customField['field_name']] = customField.value
        })

        const hasRequiredCustomFields = hasRequiredFields(userData)
        userData.completeProfile = hasRequiredCustomFields
        consoleLog('has required CustomFields', hasRequiredCustomFields)
        if (hasVerification && hasRequiredCustomFields) {
          userData.verified = true
        }
        if (!hasVerification || !hasRequiredCustomFields) {
          userData.verified = false
        }
      }

      setData(userData)
    }
  }, [extendedUserData, experienceData])

  consoleLog('data', data)
  return data
}

export default useUserData
